import {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { Container, CustomInput } from 'reactstrap';

import { Spinner, Table, FormFooterActions } from '@atoms';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';
import useToast from '@hooks/useToast';

const EditPIOs = (props) => {
  const {
    match: {
      params: { planProductId, productId },
    },
  } = props;

  const { get, post, isPending } = useApiFetch();
  const { showErrorToast } = useToast();
  const { getQueryParam, buildQueryParams } = useQueryParams();

  const [pioIds, setPioIDs] = useState([]);
  const [piosData, setPiosData] = useState([]);

  const search = getQueryParam('search');
  const sortPlanName = getQueryParam('sortPlanName');
  const sortStatus = getQueryParam('sortStatus');
  const planId = getQueryParam('planId');
  const page = getQueryParam('from');

  const getPlanProductPios = useCallback(async () => {
    const response = await get(
      `plans/planproducts/planproductpios?planProductID=${planProductId}&productID=${productId}`,
    );
    setPiosData(response?.data);
  }, [planProductId, productId]);

  useEffect(() => {
    getPlanProductPios();
  }, [getPlanProductPios]);

  const activePlanProductPios = useMemo(
    () => (piosData?.length
        ? piosData.filter(data => data?.status === 'Active')
        : []),
    [piosData],
  );

  const navigatePathQuery = useMemo(() => {
    const query = buildQueryParams({
      search,
      sortPlanName,
      sortStatus,
    });
    return `/plans?page=${page}&id=${planId}${query}`;
  }, [planId]);

  const getSelectedPioIds = selectedPIOs => setPioIDs((prevIds) => {
      const currentIds = prevIds.map(pio => pio?.pioid);
      const newSelectedIds = selectedPIOs?.map(item => item?.pioid);
      const updatedIds = [
        ...selectedPIOs
          .filter(item => !currentIds.includes(item?.pioid))
          .map(item => ({ pioid: item?.pioid })),
        ...prevIds.filter(pio => newSelectedIds.includes(pio?.pioid)),
      ];
      return updatedIds;
    });

  useEffect(() => {
    const selectedPIOs = piosData?.filter(item => item?.pioSelected);
    selectedPIOs?.length ? getSelectedPioIds(selectedPIOs) : [];
  }, [piosData]);

  const handleCheckboxChange = (pioid) => {
    setPioIDs((prevIds) => {
      const isSelected = prevIds.some(val => val.pioid === pioid);
      if (isSelected) {
        return prevIds.filter(id => id.pioid !== pioid);
      }
      return [...prevIds, { pioid }];
    });
  };

  const column = [
    {
      id: 'pioName',
      header: <div className="pl-4 ml-3">Investment Option</div>,
      size: '75%',
      cell: (tableRow) => {
        const { pioName, pioid } = tableRow?.row?.original || {};
        const isSelectedPioIds = pioIds?.some(val => val?.pioid === pioid);
        return (
          <div key={pioid} className="flex">
            <CustomInput
              type="checkbox"
              name={`pioid-${pioid}`}
              id={pioid}
              onChange={() => handleCheckboxChange(pioid)}
              checked={isSelectedPioIds}
            />
            <span className="ml-3">{pioName}</span>
          </div>
        );
      },
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
    },
  ];

  const handleSubmit = useCallback(() => {
    if (!pioIds?.length) {
      showErrorToast('At least one Investment Option must be selected.');
    } else {
      post(
        `plans/planproducts/planproductpios?planproductid=${planProductId}`,
        pioIds,
        { redirectUrl: navigatePathQuery },
      );
    }
  }, [pioIds, planProductId]);

  const tableFooter = useMemo(
    () => (
      <FormFooterActions
        linkProps={[{ path: navigatePathQuery }]}
        buttonProps={[{ onClick: handleSubmit }]}
      />
    ),
    [handleSubmit, navigatePathQuery],
  );

  return (
    <Container>
      <h3 className="mb-3">Product Investment Options</h3>
      <Spinner requesting={isPending}>
        <Table
          columns={column}
          data={activePlanProductPios}
          hidePagination
          footer={tableFooter}
        />
      </Spinner>
    </Container>
  );
};

export default EditPIOs;
