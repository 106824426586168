import {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { Card, CardBody } from 'reactstrap';

import { Spinner, Table, TableDashboard } from '@atoms';
import { formatCurrency } from '@containers/components/helpers';
import useApiFetch from '@hooks/useApiFetch';
import { easternTimeZone } from '@lib/date';

import DailySettlementSummaryReportTableSearch from './components/DailySettlementSummaryReportTableSearch';
import ExportButton from './components/ExportButton';

const renderColumnHeader = columnName => (
  <div className="column-end">{columnName}</div>
);

const renderColumnContent = (cellName, message) => (
  <div className="column-end">{message ? 'NA' : formatCurrency(cellName)}</div>
);

const custodianColumn = [
  {
    id: 'custodianName',
    header: 'Custodian Name',
    size: 200,
    cell: (tableRow) => {
      const { custodianName } = tableRow.row.original || {};
      return <span className="pl-4">{custodianName}</span>;
    },
  },
  {
    id: 'fundsDueCustodian',
    header: () => renderColumnHeader('Funds Due Custodian'),
    size: 200,
    cell: (tableRow) => {
      const { fundsDueCustodian, message } = tableRow.row.original || {};
      return renderColumnContent(fundsDueCustodian, message);
    },
  },
  {
    id: 'fundsDueIpx',
    header: () => renderColumnHeader('Funds Due IPX'),
    size: 200,
    cell: (tableRow) => {
      const { fundsDueIpx, message } = tableRow.row.original || {};
      return renderColumnContent(fundsDueIpx, message);
    },
  },
  {
    id: 'netAmount',
    header: () => renderColumnHeader('Net Amount'),
    size: 200,
    cell: (tableRow) => {
      const { netAmount, message } = tableRow.row.original || {};
      return (
        <div className="pr-0">{renderColumnContent(netAmount, message)}</div>
      );
    },
  },
];

const carrierColumn = [
  {
    id: 'carrierName',
    header: 'Carrier Name',
    size: 200,
    cell: (tableRow) => {
      const { carrierName } = tableRow.row.original || {};
      return <span className="pl-4">{carrierName}</span>;
    },
  },
  {
    id: 'fundsDueCarrier',
    header: () => renderColumnHeader('Funds Due Carrier'),
    size: 200,
    cell: (tableRow) => {
      const { fundsDueCarrier, message } = tableRow.row.original || {};
      return renderColumnContent(fundsDueCarrier, message);
    },
  },
  {
    id: 'fundsDueIpx',
    header: () => renderColumnHeader('Funds Due IPX'),
    size: 200,
    cell: (tableRow) => {
      const { fundsDueIpx, message } = tableRow.row.original || {};
      return renderColumnContent(fundsDueIpx, message);
    },
  },
  {
    id: 'netAmount',
    header: () => renderColumnHeader('Net Amount'),
    size: 200,
    cell: (tableRow) => {
      const { netAmount, message } = tableRow.row.original || {};
      return (
        <div className="pr-0">{renderColumnContent(netAmount, message)}</div>
      );
    },
  },
];

const DailySettlementReport = () => {
  const { isPending, get } = useApiFetch();
  const [dailySummaryData, setDailySummaryData] = useState({});

  const [fromDate, setFromDate] = useState(easternTimeZone);

  const {
    fundsDueTotalCustodian,
    fundsDueTotalCarrier,
    fundsDueIpxTotalCustodian,
    fundsDueIpxTotalCarrier,
    netTotalCustodian,
    netTotalCarrier,
    fundsDueOutGrandTotal,
    fundsDueInGrandTotal,
    netGrandTotal,
  } = dailySummaryData || {};

  const reportDataProps = useMemo(
    () => ({
      Custodian: {
        fundsDueTotal: fundsDueTotalCustodian,
        fundsIpxDueTotal: fundsDueIpxTotalCustodian,
        netTotal: netTotalCustodian,
      },
      Carrier: {
        fundsDueTotal: fundsDueTotalCarrier,
        fundsIpxDueTotal: fundsDueIpxTotalCarrier,
        netTotal: netTotalCarrier,
      },
    }),
    [
      fundsDueIpxTotalCarrier,
      fundsDueIpxTotalCustodian,
      fundsDueTotalCarrier,
      fundsDueTotalCustodian,
      netTotalCarrier,
      netTotalCustodian,
    ],
  );

  const getDailySettlementSummary = useCallback(async () => {
    const response = await get(
      `reports/dailysettlementsummary?${!fromDate ? '' : `date=${fromDate}`}`,
    );
    setDailySummaryData(response);
  }, [fromDate]);

  useEffect(() => {
    getDailySettlementSummary();
  }, [getDailySettlementSummary]);

  const renderSubTotal = useCallback(
    (heading) => {
      const { fundsDueTotal, fundsIpxDueTotal, netTotal } = reportDataProps[heading] || {};
      return (
        <div className="flex w-100 justify-content-between mt-3">
          <div className="w-100 pl-5">Sub Total {heading}</div>
          <div className="column-end pr-2 border-top border-dark">
            {formatCurrency(fundsDueTotal)}
          </div>
          <div className="column-end pl-3 border-top border-dark">
            {formatCurrency(fundsIpxDueTotal)}
          </div>
          <div className="column-end pl-3 border-top border-dark">
            {formatCurrency(netTotal)}
          </div>
        </div>
      );
    },
    [reportDataProps],
  );

  const tableData = [
    {
      id: 'custodianColumn',
      column: custodianColumn,
      columnData: dailySummaryData?.custodianSettlement,
      footer: renderSubTotal('Custodian'),
    },
    {
      id: 'carrierColumn',
      column: carrierColumn,
      columnData: dailySummaryData?.carrierSettlement,
      footer: renderSubTotal('Carrier'),
    },
  ];

  const summaryFooter = () => (
    <div className="flex w-100 pb-4 pl-4 pr-3">
      <div className="w-100 font-weight-bold">Grand Total</div>
      <div className="column-end pr-1 font-weight-bold">
        {formatCurrency(fundsDueOutGrandTotal)}
      </div>
      <div className="column-end pr-0 font-weight-bold">
        {formatCurrency(fundsDueInGrandTotal)}
      </div>
      <div className="column-end pr-1 font-weight-bold">
        {formatCurrency(netGrandTotal)}
      </div>
    </div>
  );

  return (
    <TableDashboard pageTitle="Retirement Edge Settlement Report">
      <Spinner requesting={isPending}>
        <Card className="pb-4 settlement-report-wrapper">
          <CardBody className="py-2 mb-2">
            <DailySettlementSummaryReportTableSearch
              fromDate={fromDate}
              setFromDate={setFromDate}
            />
          </CardBody>
          <CardBody className="px-0 pb-0">
            <ExportButton fromDate={fromDate} />
            <div className="w-max-content">
              {tableData?.map(({
                id,
                column,
                columnData = [],
                footer,
              }) => (
                <Table
                  key={id}
                  columns={column}
                  data={columnData}
                  footer={footer}
                  hidePagination
                />
              ))}
              {summaryFooter()}
            </div>
          </CardBody>
        </Card>
      </Spinner>
    </TableDashboard>
  );
};

export default DailySettlementReport;
