import React, {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { Container } from 'reactstrap';

import { sanitizeValue } from '@containers/components/helpers';
import { FormSpinner } from '@containers/FormSpinner';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';
import useToast from '@hooks/useToast';

import UserForm from '../UsersForm/UserForm';

const Edituser = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const { get, put, isPending } = useApiFetch();
  const [userDetail, setUserDetail] = useState({});

  const { validateRequiredField } = useToast();
  const { getQueryParam, buildQueryParams } = useQueryParams();

  const fromPage = getQueryParam('from');
  const search = getQueryParam('search');
  const sortUserType = getQueryParam('sortUserType');
  const sortUserName = getQueryParam('sortUserName');
  const sortStatus = getQueryParam('sortStatus');
  const pageSize = getQueryParam('pageSize');

  const navigateToUsers = useMemo(() => {
    const params = buildQueryParams({
      search,
      sortUserType,
      sortUserName,
      sortStatus,
      pageSize,
    });

    return `/users?page=${fromPage}${params}`;
  }, [pageSize, search, sortStatus, sortUserName, sortUserType]);

  const getUser = useCallback(async () => {
    const response = await get(`users/${id}`);
    setUserDetail(response?.data);
  }, [id]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const handelEditUser = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === 'string') {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const { userName, loginName } = values || {};

    const isValid = validateRequiredField({
      'User Name': userName,
      'Login Name': loginName,
    });

    if (!isValid) {
      return;
    }
    put(`users/${id}`, values, { redirectUrl: navigateToUsers });
  };

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">User Detail</h3>
      <FormSpinner requesting={isPending} />
      <UserForm
        data={userDetail}
        id={id}
        redirectUrl={navigateToUsers}
        onSubmit={handelEditUser}
      />
    </Container>
  );
};

export default Edituser;
