import { useCallback, useEffect, useState } from 'react';

import { Spinner, Table } from '@atoms';
import { pagination } from '@config/conf';
import {
  formatCurrency,
  formatDate,
  formatDateWithoutTime,
  formatToPercentage,
  getPagination,
  } from '@containers/components/helpers';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';

const subAccountsColumns = [
  {
    id: 'pioName',
    header: 'PIO Name',
    accessorKey: 'pioName',
    enableSorting: false,
    size: '200px',
  },
  {
    id: 'carrierPIOCode',
    header: 'PIO Code',
    accessorKey: 'carrierPIOCode',
    enableSorting: false,
    size: '75px',
  },
  {
    id: 'subAccountBalance',
    header: 'Balance',
    alignRight: true,
    size: '100px',
    cell: (tableRow) => {
      const { subAccountBalance } = tableRow?.row?.original || {};
      return (
        <div className="text-right">
          {subAccountBalance ? formatCurrency(subAccountBalance) : '$0.00'}
        </div>
      );
    },
  },
  {
    id: 'currentRate',
    header: 'Current Rate',
    alignRight: true,
    size: '100px',
    cell: (tableRow) => {
      const { currentRate } = tableRow?.row?.original || {};
      return (
        <div className="text-right">
          {currentRate ? formatToPercentage(currentRate, 0) : ''}
        </div>
      );
    },
  },
  {
    id: 'marginRate',
    header: 'Margin Rate',
    alignRight: true,
    size: '100px',
    cell: (tableRow) => {
      const { marginRate } = tableRow?.row?.original || {};
      return (
        <div className="text-right">
          {marginRate ? formatToPercentage(marginRate, 0) : ''}
        </div>
      );
    },
  },
  {
    id: 'participationRate',
    header: 'Participation Rate',
    alignRight: true,
    size: '130px',
    cell: (tableRow) => {
      const { participationRate } = tableRow?.row?.original || {};
      return (
        <div className="text-right">
          {participationRate ? formatToPercentage(participationRate, 0) : ''}
        </div>
      );
    },
  },
  {
    id: 'indexLockRate',
    header: 'Index Lock Rate',
    alignRight: true,
    size: '125px',
    cell: (tableRow) => {
      const { indexLockRate } = tableRow?.row?.original || {};
      return (
        <div className="text-right">
          {indexLockRate ? formatToPercentage(indexLockRate, 0) : ''}
        </div>
      );
    },
  },
  {
    id: 'capRate',
    header: 'Cap Rate',
    alignRight: true,
    size: '75px',
    cell: (tableRow) => {
      const { capRate } = tableRow?.row?.original || {};
      return (
        <div className="text-right">
          {capRate ? formatToPercentage(capRate, 0) : ''}
        </div>
      );
    },
  },
  {
    id: 'floorRate',
    header: 'Floor Rate',
    alignRight: true,
    size: '90px',
    cell: (tableRow) => {
      const { floorRate } = tableRow?.row?.original || {};
      return (
        <div className="text-right">
          {floorRate ? formatToPercentage(floorRate, 0) : ''}
        </div>
      );
    },
  },
  {
    id: 'povDate',
    header: 'POV Date',
    size: '100px',
    cell: (tableRow) => {
      const { povDate } = tableRow?.row?.original || {};
      return <div>{formatDateWithoutTime(povDate)}</div>;
    },
  },
  {
    id: 'lastUpdated',
    header: 'Last Updated',
    cell: (tableRow) => {
      const { lastUpdated } = tableRow?.row?.original || {};
      return <div>{formatDate(lastUpdated)}</div>;
    },
  },
];

const SubAccountsTable = ({ id }) => {
  const { isPending, get } = useApiFetch();
  const { getQueryParam } = useQueryParams();

  const pageQuery = getQueryParam('subaccountsPage');
  const [pageSize, setPageSize] = useState(
    getQueryParam('pageSize') || pagination.length,
  );
  const [subAccounts, setSubAccounts] = useState({});

  const { offset, pageNumber } = getPagination(pageSize, pageQuery);

  const { data: tableData = [], recordCount = 0 } = subAccounts || {};

  const getSubAccounts = useCallback(async () => {
    const data = await get(`reaccounts/subaccounts/${id}?Offset=${offset}&Limit=${pageSize}`);
    setSubAccounts(data);
  }, [id, offset, pageSize]);

  useEffect(() => {
    getSubAccounts();
  }, [getSubAccounts]);

  const pageProps = {
    total: recordCount,
    pageSize,
    setPageSize,
    pageNumber,
    pageName: 'subaccountsPage',
  };

  return (
    <Spinner requesting={isPending}>
      <Table
        columns={subAccountsColumns}
        data={tableData}
        pageProps={pageProps}
        noPadding
        noBottomBorder
      />
    </Spinner>
  );
};

export default SubAccountsTable;
