import {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { Container } from 'reactstrap';

import { sanitizeValue } from '@containers/components/helpers';
import FormSpinner from '@containers/FormSpinner/FormSpinner';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';

import WorkflowsForm from './components/WorkflowsForm';

const EditWorkflow = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const { get, put, isPending } = useApiFetch();
  const { getQueryParam, buildQueryParams } = useQueryParams();

  const [singleWorkflow, setSingleWorkflow] = useState({});

  const page = getQueryParam('from');
  const pageSize = getQueryParam('pageSize');

  const search = {
    searchInput: getQueryParam('searchInput'),
    wfType: getQueryParam('wfType'),
    statusInput: getQueryParam('statusInput'),
    fromDate: getQueryParam('fromDate'),
    toDate: getQueryParam('toDate'),
  };

  const sortWFNumber = getQueryParam('sortWFNumber');
  const sortWType = getQueryParam('sortWType');
  const sortWDate = getQueryParam('sortWDate');
  const sortReAccountNumber = getQueryParam('sortReAccountNumber');

  const getSingleWorkflow = useCallback(async () => {
    const data = await get(`workflows/${id}`);
    setSingleWorkflow(data);
  }, [id]);

  useEffect(() => {
    getSingleWorkflow();
  }, [getSingleWorkflow]);

  const commonPath = useMemo(() => {
    const {
 fromDate, searchInput, statusInput, toDate, wfType,
} = search || {};
    return buildQueryParams({
      searchInput,
      wfType,
      statusInput,
      fromDate,
      toDate,
      sortWFNumber,
      sortWType,
      sortWDate,
      sortReAccountNumber,
      pageSize,
    });
  }, [
    search,
    sortWFNumber,
    sortWType,
    sortWDate,
    sortReAccountNumber,
    pageSize,
  ]);

  const goBackUrl = useMemo(
    () => `/workflows?page=${page}${commonPath}`,
    [page, commonPath],
  );

  const gotoWorkflowTypeIdUrl = useMemo(
    () => `/workflows/workflowdetail${singleWorkflow?.workflowTypeID}/${id}?from=${page}${commonPath}`,
    [singleWorkflow?.workflowTypeID, id, page, commonPath],
  );

  const handleForm = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === 'string') {
        values[key] = values[key].trim();
      }
    });

    const payloadValues = {
      ...values,
      notes: sanitizeValue(values.notes),
    };

    put(`workflows/${id}`, payloadValues, { redirectUrl: goBackUrl });
  };

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Workflow Detail</h3>
      <FormSpinner requesting={isPending} />
      <WorkflowsForm
        id={id}
        data={singleWorkflow}
        goBackUrl={goBackUrl}
        gotoWorkflowTypeIdUrl={gotoWorkflowTypeIdUrl}
        onSubmit={handleForm}
      />
    </Container>
  );
};

export default EditWorkflow;
