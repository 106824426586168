import {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { Container } from 'reactstrap';

import { FormSpinner } from '@containers/FormSpinner';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';

import RecordKeepersForm from '../RecordKeepersForm/RecordKeepersForm';

const EditRecordKeepers = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const { get, isPending } = useApiFetch();
  const { getQueryParam, buildQueryParams } = useQueryParams();

  const [recordKeepers, setRecordKeepers] = useState({});

  const page = getQueryParam('from');

  const sort = {
    sortRecordkeeperName: getQueryParam('sortRecordkeeperName') || '',
    sortRKChannel: getQueryParam('sortRKChannel') || '',
    sortStatus: getQueryParam('sortStatus') || '',
    search: getQueryParam('search'),
  };

  const pageSize = getQueryParam('pageSize') || '';

  const getSingleRecordKeeper = useCallback(async () => {
    const response = await get(`recordkeepers/${id}`);
    setRecordKeepers(response?.data);
  }, [id]);

  useEffect(() => {
    getSingleRecordKeeper();
  }, [getSingleRecordKeeper]);

  const redirectUrl = useMemo(() => {
    const {
 sortRKChannel, sortStatus, sortRecordkeeperName, search,
} = sort || {};

    const queryParams = buildQueryParams({
      sortRecordkeeperName,
      sortRKChannel,
      sortStatus,
      search,
      pageSize,
    });

    return `/recordkeepers?page=${page}${queryParams}`;
  }, [page, sort, pageSize]);

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Recordkeeper Detail</h3>

      <FormSpinner requesting={isPending} />
      <RecordKeepersForm data={recordKeepers} redirectUrl={redirectUrl} />
    </Container>
  );
};

export default EditRecordKeepers;
