import { useMemo } from 'react';

import Form from 'reactstrap/lib/Form';
import Input from 'reactstrap/lib/Input';

const commonClassName = 'table__search table__search-input mb-0';

const RecordKeeperTableSearch = ({ filter, setFilter, setSubmitSearch }) => {
  const handleClear = () => {
    setSubmitSearch(true);
    setFilter('');
  };

  const inputFields = useMemo(
    () => [
        {
          id: 'search',
          type: 'text',
          className: 'w-100',
          placeholder: 'Recordkeeper Name',
          value: filter,
          onChange: e => setFilter(e.target.value),
        },
        {
          id: 'filter',
          type: 'submit',
          className: 'btn-blue btn-w text-light',
          value: 'Filter',
        },
        filter && {
          id: 'reset',
          type: 'reset',
          value: 'Clear',
          onClick: handleClear,
          className: 'btn-w text-light',
        },
      ].filter(Boolean),
    [filter],
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitSearch(true);
  };

  return (
    <div className="table__search mb-0">
      <Form className="w-100" inline noValidate onSubmit={handleSubmit}>
        {inputFields.map((items) => {
          const {
 type, className, placeholder, value, onChange, onClick, id,
} = items || {};
          return (
            <Input
              key={id}
              type={type}
              placeholder={placeholder}
              className={`${commonClassName} ${className}`}
              value={value}
              onChange={onChange}
              onClick={onClick}
            />
          );
        })}
      </Form>
    </div>
  );
};

export default RecordKeeperTableSearch;
