import React, {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { CustomInput, Container } from 'reactstrap';

import { Spinner, Table, FormFooterActions } from '@atoms';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';
import useToast from '@hooks/useToast';

const PlanFactors = ({
  match: {
    params: { planProductId },
  },
}) => {
  const { get, isPending, post } = useApiFetch();
  const { showErrorToast } = useToast();
  const { getQueryParam, buildQueryParams } = useQueryParams();

  const [productFactorIds, setProductFactorIds] = useState([]);
  const [planProductFactor, setPlanProductFactor] = useState([]);

  const planId = getQueryParam('planId');
  const page = getQueryParam('from');
  const search = getQueryParam('search');
  const sortPlanName = getQueryParam('sortPlanName');
  const sortStatus = getQueryParam('sortStatus');

  const getPlanProductFactor = useCallback(async () => {
    const response = await get(
      `plans/planproducts/planproductfactors?planProductID=${planProductId}`,
    );
    setPlanProductFactor(response?.data);
  }, [planProductId]);

  useEffect(() => {
    getPlanProductFactor();
  }, [getPlanProductFactor]);

  useEffect(() => {
    const selectedFactors = planProductFactor?.filter(
      item => item.selected,
    );
    if (selectedFactors?.length) {
      setProductFactorIds((prevIds) => {
        const currentIds = prevIds.map(factor => factor.productFactorID);
        const newSelectedIds = selectedFactors.map(
          item => item.productFactorID,
        );

        const updatedIds = [
          ...selectedFactors
            .filter(item => !currentIds.includes(item.productFactorID))
            .map(item => ({ productFactorID: item.productFactorID })),
          ...prevIds.filter(factor => newSelectedIds.includes(factor.productFactorID)),
        ];
        return updatedIds;
      });
    } else {
      setProductFactorIds([]);
    }
  }, [planProductFactor]);

  const filteredPlanProductFactor = useMemo(
    () => planProductFactor?.filter(data => data?.status === 'Active'),
    [planProductFactor],
  );

  const navigateToPlans = useMemo(() => {
    const params = buildQueryParams({
      search,
      sortPlanName,
      sortStatus,
    });
    return `/plans?page=${page}&id=${planId}${params}`;
  }, [page, planId, search, sortPlanName, sortStatus]);

  const handleCheckboxChange = (productFactorID) => {
    setProductFactorIds((prevIds) => {
      const isSelected = prevIds.some(
        val => val.productFactorID === productFactorID,
      );
      if (isSelected) {
        return prevIds.filter(id => id.productFactorID !== productFactorID);
      }
      return [...prevIds, { productFactorID }];
    });
  };

  const columns = useMemo(
    () => [
      {
        id: 'factorName',
        header: <div className="pl-4 ml-3">Factors</div>,
        size: '75%',
        cell: ({
          row: {
            original: { productFactorID, factorName },
          },
        }) => {
          const isSelectedFactor = productFactorIds.some(
            val => val.productFactorID === productFactorID,
          );
          return (
            <div key={productFactorID} className="flex">
              <CustomInput
                type="checkbox"
                name={`factorID-${productFactorID}`}
                id={productFactorID}
                onChange={() => handleCheckboxChange(productFactorID)}
                checked={isSelectedFactor}
              />
              <span className="ml-3">{factorName}</span>
            </div>
          );
        },
      },
      {
        id: 'status',
        header: 'Status',
        accessorKey: 'status',
      },
    ],
    [productFactorIds],
  );

  const handleClick = useCallback(() => {
    if (!productFactorIds.length) {
      showErrorToast('At least one Factor must be selected.');
    } else {
      post(
        `plans/planproducts/planproductfactor?planproductid=${planProductId}`,
        productFactorIds,
        { redirectUrl: navigateToPlans },
      );
    }
  }, [productFactorIds.length, planProductId, navigateToPlans]);

  const tableFooter = useMemo(() => (
    <FormFooterActions
      linkProps={[{ path: navigateToPlans }]}
      buttonProps={[{ onClick: handleClick }]}
    />
    ), [handleClick, navigateToPlans]);

  return (
    <Container>
      <h3 className="mb-3">Factors</h3>
      <Spinner requesting={isPending}>
        <Table
          columns={columns}
          data={filteredPlanProductFactor}
          hidePagination
          footer={tableFooter}
        />
      </Spinner>
    </Container>
  );
};

export default PlanFactors;
