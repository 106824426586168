import {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { Container } from 'reactstrap';

import {
  sanitizeValue,
  splitStringyNumber,
} from '@containers/components/helpers';
import { FormSpinner } from '@containers/FormSpinner';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';

import EditCashLedgerForm from './components/EditCashLedgerForm';

const EditCashLedger = ({
  match: {
    params: { id },
  },
}) => {
  const { getQueryParam, buildQueryParams } = useQueryParams();
  const { get, isPending, put } = useApiFetch();

  const [cashLedger, setCashLedger] = useState({});

  const page = getQueryParam('from');
  const dateRangeInput = getQueryParam('dateRangeInput');
  const effectiveStartDate = getQueryParam('effectiveStartDate');
  const effectiveEndDate = getQueryParam('effectiveEndDate');
  const pageSize = getQueryParam('pageSize');

  const getSingleCashLedger = useCallback(async () => {
    const response = await get(`operations/cashledger/dailysummary/${id}`);
    setCashLedger(response?.response);
  }, [id]);

  useEffect(() => {
    getSingleCashLedger();
  }, [getSingleCashLedger]);

  const redirectUrl = useMemo(() => {
    const queryParams = buildQueryParams({
      dateRangeInput,
      effectiveStartDate,
      effectiveEndDate,
      pageSize,
    });

    return `/cash-ledger?page=${page}${queryParams}`;
  }, [
    page,
    dateRangeInput,
    effectiveStartDate,
    effectiveEndDate,
    pageSize,
    id,
  ]);

  const handleForm = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === 'string') {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const payloadValues = {
      ...values,
      adjustmentAmount: Number(splitStringyNumber(values.adjustmentAmount)),
    };

    put(`operations/cashledger/dailysummary/${id}`, payloadValues, {
      redirectUrl,
    });
  };

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Cash Ledger Summary Detail</h3>

      <FormSpinner requesting={isPending} />
      <EditCashLedgerForm
        data={cashLedger}
        onSubmit={handleForm}
        redirectUrl={redirectUrl}
      />
    </Container>
  );
};

export default EditCashLedger;
