import { useCallback, useEffect, useState } from 'react';

import { Table, Spinner } from '@atoms';
import { pagination } from '@config/conf';
import { formatDate, formatToPercentage, getPagination } from '@containers/components/helpers';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';

const accountPiosColumns = [
  {
    id: 'pioName',
    header: 'PIO Name',
    accessorKey: 'pioName',
    enableSorting: false,
    size: '300px',
  },
  {
    id: 'carrierPIOCode',
    header: 'PIO Code',
    accessorKey: 'carrierPIOCode',
    enableSorting: false,
    size: '100px',
  },
  {
    id: 'allocationRate',
    header: 'Allocation Rate',
    cell: (tableRow) => {
      const { allocationRate } = tableRow?.row?.original || {};

      return (
        <div className="text-right">
          {allocationRate ? formatToPercentage(allocationRate * 100) : ''}
        </div>
      );
    },
    alignRight: true,
  },
  {
    id: 'status',
    header: 'Status',
    accessorKey: 'status',
    enableSorting: false,
  },
  {
    id: 'lastUpdated',
    header: 'Last Updated',
    cell: (tableRow) => {
      const { lastUpdated } = tableRow?.row?.original || {};
      return lastUpdated ? formatDate(lastUpdated) : '';
    },
  },
];

const PioElectionsTable = ({ id }) => {
  const { getQueryParam } = useQueryParams();
  const { get, isPending } = useApiFetch();
  const [accountPioas, setAccountPioas] = useState({});

  const pageQuery = getQueryParam('pioasPage');
  const [pageSize, setPageSize] = useState(
    getQueryParam('pageSize') || pagination.length,
  );
  const { offset, pageNumber } = getPagination(pageSize, pageQuery);

  const getAccountPioas = useCallback(async () => {
    const data = await get(`reaccounts/pioallocations/${id}?Offset=${offset}&Limit=${pageSize}`);
    setAccountPioas(data);
  }, [id, offset, pageSize]);

  const { data: tableData = [], recordCount = 0 } = accountPioas || {};

  useEffect(() => {
    getAccountPioas();
  }, [getAccountPioas]);

  const pageProps = {
    total: recordCount,
    pageSize,
    setPageSize,
    pageNumber,
    fromPage: pageNumber,
    pageName: 'pioasPage',
  };

  return (
    <Spinner requesting={isPending}>
      <Table
        columns={accountPiosColumns}
        data={tableData}
        pageProps={pageProps}
        noPadding
        noBottomBorder
      />
    </Spinner>
  );
};

export default PioElectionsTable;
