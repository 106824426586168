import { useMemo } from 'react';

import ReactDatePicker from 'react-datepicker';
import Input from 'reactstrap/lib/Input';

import { dateRangeOptions } from '@config/conf';
import { formatDate } from '@lib/date';

const DateRangeInput = ({ dateRange, setDateRange }) => {
  const { dateRangeInput, effectiveEndDateInput, effectiveStartDateInput } = dateRange || {};

  const handleDateChange = (field, value) => {
    setDateRange(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const dateRangeInputData = useMemo(
    () => [
      {
        selectDate: effectiveStartDateInput,
        setSelectDate: date => handleDateChange('effectiveStartDateInput', date),
        placeholder: 'Effective Start Date (MM/DD/YYYY)',
      },
      {
        selectDate: effectiveEndDateInput,
        setSelectDate: date => handleDateChange('effectiveEndDateInput', date),
        placeholder: 'Effective End Date (MM/DD/YYYY)',
      },
    ],
    [effectiveEndDateInput, effectiveStartDateInput],
  );

  const onChangeDate = (e) => {
    const { value } = e.target || {};
    handleDateChange('dateRangeInput', value);
    if (!dateRangeInput === 'CustomRange') {
      setDateRange(prev => ({
        ...prev,
        effectiveStartDateInput: '',
        effectiveEndDateInput: '',
      }));
    }
  };

  return (
    <>
      <Input
        type="select"
        placeholder="Date Range"
        className="table__search-input"
        value={dateRangeInput}
        onChange={onChangeDate}
      >
        {dateRangeOptions.map(({ label, value }, index) => (
          <option key={`${value}_${index}`} value={value}>
            {label}
          </option>
        ))}
      </Input>
      {dateRangeInput === 'CustomRange' ? (
        <>
          {dateRangeInputData.map(
            ({ placeholder, selectDate, setSelectDate }) => (
              <ReactDatePicker
                key={placeholder}
                className="form-control table__search-input"
                selected={selectDate ? new Date(selectDate) : null}
                onChange={date => setSelectDate(formatDate(date))}
                placeholderText={placeholder}
              />
            ),
          )}
        </>
      ) : (
        null
      )}
    </>
  );
};

export default DateRangeInput;
