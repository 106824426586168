import {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { Container } from 'reactstrap';

import FormSpinner from '@containers/FormSpinner/FormSpinner';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';

import EditPandWsForm from './component/EditPandWsForm';

const EditPandWs = ({
  match: {
    params: { id },
  },
}) => {
  const { get, isPending } = useApiFetch();
  const { getQueryParam, buildQueryParams } = useQueryParams();

  const [data, setData] = useState({});

  const page = getQueryParam('page') || 1;
  const search = {
    pwSearch: getQueryParam('pwSearch') || '',
    pwStatus: getQueryParam('pwStatus') || '',
    pwCustodian: getQueryParam('pwCustodian') || '',
  };

  const getPurchaseAndWithdrawals = useCallback(async () => {
    const response = await get(`operations/pandw/detail/${id}`);
    setData(response?.data);
  }, [id]);

  useEffect(() => {
    getPurchaseAndWithdrawals();
  }, [getPurchaseAndWithdrawals]);

  const redirectUrl = useMemo(() => {
    const { pwCustodian, pwSearch, pwStatus } = search || {};
    const queryParams = buildQueryParams({
      pwSearch,
      pwStatus,
      pwCustodian,
    });

    return `/purchases-and-withdrawals?from=${page}${queryParams}`;
  }, [page, search]);

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Purchases &amp; Withdrawals Detail</h3>
      <FormSpinner requesting={isPending} />
      <EditPandWsForm data={data} redirectUrl={redirectUrl} />
    </Container>
  );
};

export default EditPandWs;
