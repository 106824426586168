import {
 useEffect, useState, useMemo, useCallback,
} from 'react';

import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';

import { Spinner, Table, TableDashboard } from '@atoms';
import { pagination } from '@config/conf';
import { getPagination } from '@containers/components/helpers';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';

const Custodians = () => {
  const { getQueryParam } = useQueryParams();
  const { get, isPending } = useApiFetch();

  const page = getQueryParam('page');

  const [custodiansData, setCustodianData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [pageSize, setPageSize] = useState(
    getQueryParam('pageSize') || pagination.length,
  );
  const { offset, pageNumber } = getPagination(pageSize, page);
  const { data = [], recordCount = 0 } = custodiansData || {};

  const column = useMemo(
    () => [
      {
        id: 'custodianName',
        header: 'Custodian Name',
        size: 200,
        accessorFn: row => row?.custodianName || '-',
      },
      {
        id: 'custodianCode',
        header: 'Custodian Code',
        size: 300,
        accessorFn: row => row?.custodianCode || '-',
      },
      {
        id: 'contactName',
        header: 'Contact Name',
        accessorFn: row => row?.contactName || '-',
      },
      {
        id: 'contactPhone',
        header: 'Contact Phone',
        accessorFn: row => row?.contactPhone || '-',
      },
      {
        id: 'contactEmail',
        header: 'Contact Email',
        accessorFn: row => row?.contactEmail || '-',
      },
      {
        id: 'editAction',
        header: '',
        cell: (tableRow) => {
          const { custodianID } = tableRow.row.original || {};
          const navigateToEditPage = `/custodians/${custodianID}/edit?from=${pageNumber}${
            pageSize ? `&pageSize=${pageSize}` : ''
          }`;
          return (
            <div className="column-end pr-2 my-1">
              <Link to={navigateToEditPage}>
                <Badge className="btn-blue btn-badge align-middle">
                  View / Edit
                </Badge>
              </Link>
            </div>
          );
        },
      },
    ],
    [pageNumber, pageSize],
  );

  const getCustodians = useCallback(async () => {
    const response = await get(`custodians/get?Limit=${pageSize}&Offset=${offset}`);
    setCustodianData(response);
  }, [pageSize, offset, refresh]);

  useEffect(() => {
    getCustodians();
    setRefresh(false);
  }, [getCustodians]);

  const pageProps = useMemo(
    () => ({
      total: recordCount,
      pageSize,
      setPageSize,
      pageNumber,
    }),
    [pageNumber, pageSize, recordCount],
  );

  return (
    <TableDashboard
      pageTitle="Custodians"
      setRefresh={setRefresh}
      navigatePath="/custodians/add"
    >
      <Spinner requesting={isPending}>
        <Table
          columns={column}
          data={data}
          pageProps={pageProps}
        />
      </Spinner>
    </TableDashboard>
  );
};

export default Custodians;
