import React, {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { Container } from 'reactstrap';

import {
  sanitizeValue,
  splitStringyRate,
} from '@containers/components/helpers';
import { FormSpinner } from '@containers/FormSpinner';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';
import useToast from '@hooks/useToast';

import PIOForm from '../PIOForm/PIOForm';

const EditPIO = (props) => {
  const {
    match: {
      params: { id, pioId },
    },
  } = props;

  const { get, put, isPending } = useApiFetch();
  const [productPio, setProductPio] = useState();

  const { getQueryParam, buildQueryParams } = useQueryParams();
  const { validateRequiredField } = useToast();

  const page = getQueryParam('from');
  const pageSize = getQueryParam('pageSize');

  const sort = useMemo(
    () => ({
      sortProductName: getQueryParam('sortProductName'),
      sortCarrierName: getQueryParam('sortCarrierName'),
    }),
    [],
  );

  const getProductPio = useCallback(async () => {
    const response = await get(`products/pios/${pioId}`);
    setProductPio(response);
  }, [pioId]);

  useEffect(() => {
    getProductPio();
  }, [getProductPio]);

  const redirectToProducts = useMemo(() => {
    const { sortCarrierName, sortProductName } = sort || {};
    const queryParams = buildQueryParams({
      sortProductName,
      sortCarrierName,
      pageSize,
    });

    return `/products?id=${id}&page=${page}${queryParams}`;
  }, [id, page, sort, pageSize]);

  const handleForm = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === 'string') {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const {
      pioName,
      carrierPIOCode,
      pioCreditingRate,
      pioParticipationRate,
      pioMinRate,
      pioMaxRate,
      fiaCapRate,
    } = values || {};

    const isValid = validateRequiredField({
      'Investment Option': pioName,
      'Investment Option Code': carrierPIOCode,
    });

    if (!isValid) {
      return;
    }

    const payloadValues = {
      ...values,
      pioCreditingRate: splitStringyRate(pioCreditingRate) || '',
      pioParticipationRate: splitStringyRate(pioParticipationRate) || '',
      fiaCapRate: splitStringyRate(fiaCapRate) || '',
      pioMinRate: splitStringyRate(pioMinRate) || '',
      pioMaxRate: splitStringyRate(pioMaxRate) || '',
    };

    put(`products/pios/${pioId}`, payloadValues, {
      redirectUrl: redirectToProducts,
    });
  };

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Product Investment Option Detail</h3>

      <FormSpinner requesting={isPending} />
      <PIOForm
        data={productPio}
        onSubmit={handleForm}
        redirectUrl={redirectToProducts}
      />
    </Container>
  );
};

export default EditPIO;
