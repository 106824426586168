import { useEffect, useRef, useState } from 'react';

import { useIdleTimer } from 'react-idle-timer';

import { INACTIVE_TIMEOUT } from '@config/conf';
import { useAuth } from '@contexts/AuthContext';

import AnnuityTabs from './AnnuityTabs';
import Navbar from './Navbar';

const ViewAccountForm = ({
 activeTab, setActiveTab, data, errorResponse,
}) => {
  const { annuityLogout } = useAuth();
  const idleTimerRef = useRef(null);
  const [showError, setShowError] = useState(false);
  window.onbeforeunload = null;

  const { validToken, annuityAuthToken, reAccountNumber } = sessionStorage || {};
  const hasAuthError = errorResponse === 'Unauthorized';
  const isInvalidSession = !validToken && !reAccountNumber && !annuityAuthToken;
  const userFirstName = data?.[0]?.firstName;

  sessionStorage.setItem('userFirstName', userFirstName);

  const tabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleInvalidToken = () => {
    sessionStorage.setItem('validToken', false);
    annuityLogout();
  };

  useEffect(() => {
    if (isInvalidSession) {
      setShowError(true);
    }

    const handleStorageChange = (event) => {
      if (event.storageArea === sessionStorage) {
        if (!sessionStorage.annuityAuthToken) {
          handleInvalidToken();
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [isInvalidSession]);

  const idleTimer = useIdleTimer({
    crossTab: true,
    ref: idleTimerRef,
    timeout: INACTIVE_TIMEOUT,
    onIdle: () => {
      handleInvalidToken();
    },
  });

  return (
    <div idletimer={idleTimer}>
      {!hasAuthError && (errorResponse || showError) ? (
        <div className="pt-5">
          <h3 className="text-center text-danger">
            {errorResponse || (showError && 'Invalid token')}
          </h3>
        </div>
      ) : data?.length ? (
        <>
          <Navbar
            userFirstName={userFirstName}
            invalidateAnnuitantToken={annuityLogout}
          />
          <AnnuityTabs
            className="mt-5"
            tabShown={activeTab}
            data={data}
            tabClick={tabClick}
          />
        </>
      ) : null}
    </div>
  );
};

export default ViewAccountForm;
