import React, {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { useForm } from 'react-hook-form';
import { Container } from 'reactstrap';

import { sanitizeValue } from '@containers/components/helpers';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';

import PlanProductForm from '../components/PlanProductForm';

const EditPlanProduct = (props) => {
  const editPlanProductForm = useForm({
    mode: 'onChange',
  });

  const {
    match: {
      params: { planId, productId: planProductId },
    },
  } = props;

  const { get, isPending, put } = useApiFetch();
  const [planProduct, setPlanProduct] = useState({});
  const { getQueryParam, buildQueryParams } = useQueryParams();

  const pageNumber = getQueryParam('from');
  const search = getQueryParam('search');
  const sortPlanName = getQueryParam('sortPlanName');
  const sortStatus = getQueryParam('sortStatus');
  const pageSize = getQueryParam('pageSize');

  const getPlanProduct = useCallback(async () => {
    const response = await get(`plans/planproducts/${planProductId}`);
    setPlanProduct(response);
  }, [planProductId]);

  useEffect(() => {
    getPlanProduct();
  }, [getPlanProduct]);

  const navigateToPlans = useMemo(() => {
    const params = buildQueryParams({
      search,
      sortPlanName,
      sortStatus,
      pageSize,
    });
    return `/plans?page=${pageNumber}&id=${planId}${params}`;
  }, [pageNumber, pageSize, planId, search, sortPlanName, sortStatus]);

  const handleEditForm = useCallback(
    (values) => {
      const payloadValues = {
        ...values,
        carrierPlanIdentifier: sanitizeValue(values.carrierPlanIdentifier),
      };
      put(`plans/${planId}/planproducts/${planProductId}`, payloadValues, {
        redirectUrl: navigateToPlans,
      });
    },
    [planId, planProductId],
  );

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Plan Product Detail</h3>
      <PlanProductForm
        form={editPlanProductForm}
        data={planProduct}
        planProductId={planProductId}
        planId={planId}
        onSubmit={handleEditForm}
        loadingResponse={isPending}
      />
    </Container>
  );
};

export default EditPlanProduct;
