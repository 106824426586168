import React, { useMemo } from 'react';

import moment from 'moment';
import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';

import { ExportToCsv, DateRangeInput } from '@atoms';
import useCsvExportButton from '@hooks/useCsvExportButton';
import useQueryParams from '@hooks/useQueryParams';
import { formatDate } from '@lib/date';

const CashLedgerTableSearch = ({
  setSubmitSearch,
  setDateRange,
  dateRange,
}) => {
  const currentDateAndTime = moment();
  const { buildQueryParams } = useQueryParams();
  const { dateRangeInput, effectiveStartDateInput, effectiveEndDateInput } = dateRange || {};

  const {
    csvClickRef,
    csvData: { response: exportData = [] },
    getExport,
    loadingResponse,
  } = useCsvExportButton();

  const handleCsvExport = () => {
    const queryParams = buildQueryParams({
      effectiveStartDate: effectiveStartDateInput,
      effectiveEndDate: effectiveEndDateInput,
    });

    getExport(
      `operations/cashledger/exportCashLedgerTable${
        dateRangeInput ? `?dateRangeRequested=${dateRangeInput}` : ''
      }${queryParams}`,
    );
  };

  const handleClear = (e) => {
    e.preventDefault();
    setSubmitSearch(true);
    setDateRange(prev => ({
      ...prev,
      dateRangeInput: 'Today',
      effectiveStartDateInput: '',
      effectiveEndDateInput: '',
    }));
  };

  const csvFileName = useMemo(() => {
    const csvDate = formatDate(currentDateAndTime, 'YYYYMMDD_HHmmss');
    return `CashLedger_${csvDate}.csv`;
  }, [currentDateAndTime]);

  const shouldShowClearButton = dateRangeInput || effectiveStartDateInput || effectiveEndDateInput;

  return (
    <div className="table__search mb-0">
      <Form
        inline
        className="w-100"
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitSearch(true);
        }}
      >
        <DateRangeInput dateRange={dateRange} setDateRange={setDateRange} />
        <Button
          color="blue"
          className="text-light table__search-input mb-0"
          // disabled={!enableButtons}
        >
          Filter
        </Button>
        <ExportToCsv
          csvData={exportData}
          csvRef={csvClickRef}
          fileName={csvFileName}
          getCsvExport={handleCsvExport}
          loadingResponse={loadingResponse}
          colorVariant="blue"
          className="m-0 text-light table__search-input"
        />
        {shouldShowClearButton ? (
          <Button
            type="reset"
            color="blue"
            className="text-light table__search-input mb-0"
            onClick={handleClear}
          >
            Clear
          </Button>
        ) : null}
      </Form>
    </div>
  );
};

export default CashLedgerTableSearch;
