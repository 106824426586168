import React, { useState } from 'react';

import { TableDashboard } from '@atoms/index';

import PurchasesWithdrawalsTable from './components/PurchasesWithdrawalsTable';

const PurchasesWithdrawals = () => {
  const [refresh, setRefresh] = useState(false);

  // There are no updates to show import-purchase-withdrawal page
  return (
    <TableDashboard
      pageTitle="Purchases &amp; Withdrawals"
      setRefresh={setRefresh}
      // navigateLabel="Import"
      // navigatePath="/purchases-and-withdrawals/import"
    >
      <PurchasesWithdrawalsTable refresh={refresh} setRefresh={setRefresh} />
    </TableDashboard>
  );
};

export default PurchasesWithdrawals;
