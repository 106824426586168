import { useEffect, useState } from 'react';

import { Form, Input } from 'reactstrap';

import { DateRangeInput } from '@atoms';
import useApiFetch from '@hooks/useApiFetch';

const PurchasesWithdrawalsSearch = ({
  pwSearch,
  setPwSearch,
  pwStatus,
  setPwStatus,
  pwCustodian,
  setPwCustodian,
  setSubmitSearch,
  dateRange,
  setDateRange,
}) => {
  const { get, isPending } = useApiFetch();
  const [custodiansData, setCustodianData] = useState([]);

  const getCustodians = async () => {
    const response = await get('custodians/get');
    setCustodianData(response?.data);
  };

  useEffect(() => {
    getCustodians();
  }, []);

  const handleClear = (e) => {
    e.preventDefault();
    setSubmitSearch(true);
    setPwSearch('');
    setPwStatus('');
    setPwCustodian('');
    setDateRange(prev => ({
      ...prev,
      dateRangeInput: 'Today',
      effectiveStartDateInput: '',
      effectiveEndDateInput: '',
    }));
  };

  const { dateRangeInput, effectiveStartDateInput, effectiveEndDateInput } = dateRange || {};

  const shouldShowClearButton = pwSearch
    || pwStatus
    || pwCustodian
    || dateRangeInput
    || effectiveEndDateInput
    || effectiveStartDateInput;

  return (
    <div className="table__search mb-0">
      <Form
        inline
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitSearch(true);
        }}
      >
        <DateRangeInput dateRange={dateRange} setDateRange={setDateRange} />
        <Input
          type="text"
          placeholder="WF#"
          maxLength="20"
          className="table__search table__search-input mb-0"
          value={pwSearch}
          onChange={e => setPwSearch(e.target.value)}
        />
        <Input
          type="select"
          className="table__search table__search-input mb-0"
          value={pwStatus}
          onChange={e => setPwStatus(e.target.value)}
        >
          <option value="" />
          <option value="In Progress">In Progress</option>
          <option value="Completed">Completed</option>
          <option value="Failed">Failed</option>
          <option value="Error">Error</option>
        </Input>
        <Input
          type="select"
          className="table__search table__search-input mb-0"
          value={pwCustodian}
          onChange={e => setPwCustodian(e.target.value)}
        >
          <option value="" />
          {isPending ? <option value="">Loading Custodians...</option> : null}
          {custodiansData?.map((item, index) => (
            <option value={item.custodianID} key={index}>
              {item.custodianName}
            </option>
          ))}
        </Input>
        <Input
          type="submit"
          className="btn-blue btn-w text-light table__search table__search-input mb-0"
          value="Filter"
        />
        {shouldShowClearButton ? (
          <Input
            type="reset"
            className="btn-w text-light table__search table__search-input mb-0"
            value="Clear"
            onClick={handleClear}
          />
        ) : null}
      </Form>
    </div>
  );
};

export default PurchasesWithdrawalsSearch;
